export const size = {
  xs: "320px",
  xs_down: "400px",
  iphone_down: "431px",
  sm: "768px",
  lg: "1200px",
};
export const device = {
  xs: `(min-width: ${size.xs})`,
  xs_down: `(max-width: ${size.xs_down})`,
  iphone_down: `(max-width: ${size.iphone_down})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`,
};

export const Colors = {
  AgendaBackground: "#fceae0",
  DestinationBackground: "#fce8f8",
  InformationBackground: "#f9ecff",
};

import React from "react";
import { ButtonGroup } from "react-bootstrap";
import styled from "styled-components";
import { StyledButton } from "./shared/SharedStyledButton";

const StyledNavigationBarWrapper = styled.div`
  transform: scale(1);
  transition: transform 0.5s ease;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
    }
  }
`;
const Navbar = ({ handleButtonNavigation, activeSection }) => {
  return (
    <StyledNavigationBarWrapper>
      <ButtonGroup size="md">
        <StyledButton
          size="md"
          active={activeSection === 2}
          onClick={() => handleButtonNavigation("destination")}
        >
          Destination
        </StyledButton>

        <StyledButton
          size="md"
          active={activeSection === 3}
          onClick={() => handleButtonNavigation("agenda")}
        >
          Agenda
        </StyledButton>

        <StyledButton
          size="md"
          active={activeSection === 4}
          onClick={() => handleButtonNavigation("info")}
        >
          Information
        </StyledButton>
      </ButtonGroup>
    </StyledNavigationBarWrapper>
  );
};

export default Navbar;

import React from "react";
import { StyledAgenda } from "./StyledPages";
import styled from "styled-components";

const StyledHeader = styled.h2`
  margin-bottom: 20px;
  color: #395442;
`;
const StyledBody = styled.div`
  color: #395442;
`;
const Agenda = ({ agendaRef, marginScrollTop }) => {
  return (
    <StyledAgenda ref={agendaRef} marginscrolltop={marginScrollTop}>
      <StyledHeader>Agenda</StyledHeader>
      <StyledBody>
        <div>
          Klockan 14:45 inleds vigseln i kyrkan, en ceremoni som beräknas pågå i
          cirka en timme. Efter att ceremonin är avslutad, är ni varmt välkomna
          till Carlshälls Gård för middag och fest. Vi kommer att vistas i
          Herrgårdsvillan på Carlshälls Gård, som en gång var Brännvinskungen
          L.O. Smiths sommarresidens. Här kommer vi att ha tillgång till hela
          huset och den vackra sjöterrassen utanför.
        </div>
        <br></br>
        <div>
          I Herrgårdsvillans stora matsal kommer ni att serveras en
          trerättersmiddag. För er som har ett intresse för historia kan det
          vara fascinerande att veta att det var i samma matsal som Mary, dotter
          till Brännvinskungen L.O. Smith, gifte sig med prins Jean Karadja,
          Turkiets ambassadör i Sverige. I denna matsal finns också en
          världsunik grisailletapet som Smith lät sätta upp 1887 i samband med
          sin dotters bröllop.
        </div>
        <br></br>
        <div>
          Efter middagen kommer personalen att förbereda matsalen för dans och
          hålligång. Baren öppnas strax därefter. Festen fortsätter ända fram
          till klockan 01:00, då det är dags att avsluta en minnesvärd dag full
          av kärlek och glädje.{" "}
        </div>
        <br></br>
        <div>
          Självklart vill vi att ni också ska vara med och sätta tonen på
          dansgolvet. Därför uppmanar vi er att dela era önskemål om den
          perfekta dansgolvslåten genom att klicka denna{" "}
          <a
            href="https://open.spotify.com/playlist/7y8MAql6dM0vW9RHSOeOMr?si=9f7ae8f4534643a8&pt=ef27077e36f02b26fb9fe1058444c943"
            target="_blank"
            rel="noopener noreferrer"
          >
            spotify
          </a>{" "}
          länk.{" "}
        </div>
        {/*
        <br></br>
        <br></br>
        <iframe
          title="Spotify"
          style={{ borderRadius: "12px" }}
          src="https://open.spotify.com/embed/playlist/7y8MAql6dM0vW9RHSOeOMr?utm_source=generator"
          width="100%"
          height="152"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
        <br></br>
        */}
        <br></br>
        <div>
          Utöver det kommer varje bord att ha en QR-kod för er att skanna,
          vilket kommer att ta er till en app där ni har som uppgift att ta ett
          bestämt antal bilder var från kvällen. Dagen efter kommer alla dessa
          bilder vara synliga för samtliga gäster och vi kommer att utse en
          vinnare för den bäst tagna bilden. Mer om detta får ni höra på plats!
        </div>
        <br></br>
        <div>
          Sist men inte minst, om ni har några frågor eller funderingar, tveka
          inte att höra av er till oss!
        </div>
        <div style={{ positions: "relative" }}></div>
      </StyledBody>
    </StyledAgenda>
  );
};

export default Agenda;

import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import {
  StyledApp,
  StyledCenterArea,
  StyledLeftArea,
  StyledMainPage,
  StyledStickyHeader,
  StyledRightArea,
  StyledHeaderBottom,
  StyledDate,
  StyledHeaderTop,
  StyledTitle,
  StyledImage,
} from "./StyledApp";
import RSVP from "./server/RSVP";
import Destination from "./components/pages/Destination";
import Agenda from "./components/pages/Agenda";
import Info from "./components/pages/Info";
import useScrollSpy from "./components/hooks/useScrollSpy";
import Navbar from "./components/Navbar";
import { Colors } from "./components/shared/Constants";
import { Image } from "react-bootstrap";
import SistaBilden from "./assets/Sistabilden.jpg";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth, signInAnonymously } from "firebase/auth";

const App = () => {
  const infoRef = useRef(null);
  const agendaRef = useRef(null);
  const destinationRef = useRef(null);
  const headerRefTop = useRef(null);
  const headerRefBottom = useRef(null);

  const bottomRef = useRef(null);
  const centerAreaRef = useRef(null);
  const scrollingRefs = [
    headerRefTop,
    headerRefBottom,
    destinationRef,
    agendaRef,
    infoRef,
    bottomRef,
  ];

  const auth = getAuth();
  signInAnonymously(auth);

  const [hasLoaded, setHasloaded] = useState(false);

  const stickyHeaderRef = useRef(null);

  const handleButtonNavigation = (navigateTo) => {
    if (!hasLoaded) {
      return;
    }
    switch (navigateTo) {
      case "home":
        if (centerAreaRef.current)
          centerAreaRef.current.scroll({ top: 0, left: 0, behavior: "smooth" });
        break;
      case "info":
        infoRef.current &&
          infoRef.current.scrollIntoView({ behavior: "smooth" });

        break;
      case "agenda":
        agendaRef.current &&
          agendaRef.current.scrollIntoView({ behavior: "smooth" });

        break;

      case "destination":
        destinationRef.current &&
          destinationRef.current.scrollIntoView({ behavior: "smooth" });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (
      headerRefTop.current &&
      headerRefBottom.current &&
      destinationRef.current &&
      agendaRef.current &&
      infoRef.current &&
      stickyHeaderRef.current &&
      bottomRef.current
    ) {
      setHasloaded(true);
    }
  }, []);

  const { activeSection } = useScrollSpy({
    activeSectionDefault: 0,
    scrollingElement: centerAreaRef,
    sectionElementRefs: scrollingRefs,
    throttleMs: 20,
    offsetPx:
      stickyHeaderRef.current && -stickyHeaderRef.current.scrollHeight - 10, //padding,
  });

  const stickyHeaderBackground = [
    "#f6f3e7",
    "#f6f3e7",
    Colors.DestinationBackground,
    Colors.AgendaBackground,
    Colors.InformationBackground,
    "#f6f3e7",
  ];

  const SucessfulRSVP = () => {
    toast.success("Du är nu anmäld!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: true,
      progress: undefined,
      theme: "light",
      transition: Slide,
    });
  };
  return (
    <StyledApp>
      <ToastContainer />
      <StyledMainPage>
        <StyledLeftArea></StyledLeftArea>
        <div></div>
        <StyledCenterArea ref={centerAreaRef}>
          <StyledHeaderTop ref={headerRefTop} />
          <StyledStickyHeader
            ref={stickyHeaderRef}
            stickyheaderbackground={stickyHeaderBackground[activeSection]}
          >
            <StyledTitle onClick={() => handleButtonNavigation("home")}>
              <div>Gustav</div>
              <div>&</div>
              <div>Mary</div>
            </StyledTitle>
            <Navbar
              handleButtonNavigation={handleButtonNavigation}
              activeSection={activeSection}
            />
          </StyledStickyHeader>
          <StyledHeaderBottom ref={headerRefBottom}>
            <StyledDate>Lördag den 8 juni 2024</StyledDate>
          </StyledHeaderBottom>
          <Destination
            destinationRef={destinationRef}
            marginScrollTop={
              stickyHeaderRef.current &&
              stickyHeaderRef.current.scrollHeight - 5
            }
          />
          <Agenda
            agendaRef={agendaRef}
            marginScrollTop={
              stickyHeaderRef.current &&
              stickyHeaderRef.current.scrollHeight - 5
            }
          />
          <Info
            infoRef={infoRef}
            marginScrollTop={
              stickyHeaderRef.current &&
              stickyHeaderRef.current.scrollHeight - 5
            }
          />
          <StyledImage ref={bottomRef}>
            <Image height="400px" alt="bild" src={SistaBilden}></Image>
          </StyledImage>
          <div
            style={{
              position: "sticky",
              padding: "4rem",
              display: "flex",
              justifyContent: "center",
              verticalAlign: "center",
              bottom: 10,
              right: -20,
            }}
          >
            <RSVP SucessfulRSVP={SucessfulRSVP}></RSVP>
          </div>
        </StyledCenterArea>
        {<StyledRightArea />}
      </StyledMainPage>
    </StyledApp>
  );
};

export default App;

import { useState, useEffect } from "react";
import throttle from "lodash/fp/throttle";

export default ({
  activeSectionDefault = 0,
  offsetPx = 0,
  scrollingElement,
  sectionElementRefs = [],
  throttleMs = 0,
}) => {
  const [activeSection, setActiveSection] = useState(activeSectionDefault);

  const handle = throttle(throttleMs, () => {
    let currentSectionId = activeSection;

    for (let i = 0; i < sectionElementRefs.length; i += 1) {
      const section = sectionElementRefs[i].current;

      // Needs to be a valid DOM Element
      if (!section || !(section instanceof Element)) continue;
      // GetBoundingClientRect returns values relative to viewportss
      if (section.getBoundingClientRect().top + offsetPx < 0) {
        currentSectionId = i;
        continue;
      }
      // No need to continue loop, if last element has been detected
      break;
    }

    setActiveSection(currentSectionId);
  });

  useEffect(() => {
    const scrollable = scrollingElement?.current;
    const callHandle = () => {
      handle();
    };

    if (scrollable) scrollable.addEventListener("scroll", callHandle);

    // Run initially
    //  handle();

    return () => {
      scrollable.removeEventListener("scroll", callHandle);
    };
  }, [sectionElementRefs, offsetPx, scrollingElement, handle]);
  return { activeSection };
};

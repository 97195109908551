import React, { useState } from "react";
import ModalRSVP from "../components/shared/modal/Modal";
import styled from "styled-components";
import { StyledButton } from "../components/shared/SharedStyledButton";

const StyledRSVP = styled.div`
  position: absolute;
  margin-top: auto;
`;
const RSVP = ({ SucessfulRSVP }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  return (
    <StyledRSVP>
      <StyledButton size="lg" onClick={handleOpen}>
        O.S.A
      </StyledButton>
      <ModalRSVP
        SucessfulRSVP={SucessfulRSVP}
        show={show}
        handleClose={handleClose}
      ></ModalRSVP>
    </StyledRSVP>
  );
};

export default RSVP;

import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const errorMessage = (controlId) => {
  switch (controlId) {
    case "nameId":
      return "Vänligen ange ett namn";
    case "emailId":
      return "Vänligen ange en giltig e-postadress";
    default:
      return "Någonting är fel";
  }
};

const FloatingLabelInput = ({
  controlId,
  label,
  type,
  placeholder,
  onChange,
  required,
}) => {
  return (
    <FloatingLabel controlId={controlId} label={label} className="mb-3">
      <Form.Control
        required={required}
        type={type}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
      />
      <Form.Control.Feedback type="invalid">
        {errorMessage(controlId)}
      </Form.Control.Feedback>
    </FloatingLabel>
  );
};

export default FloatingLabelInput;

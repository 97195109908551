import styled from "styled-components";
import { Button } from "react-bootstrap";

export const StyledButton = styled(Button)`
    font-weight:600;
      @media (max-width: 575px) {
    font-size: 14px;
   
  }
  --bs-btn-color: #ffdf00;
  --bs-btn-bg: #B166D6;
  --bs-btn-border-color: #632285;
  --bs-btn-active-bg: #632285;
  --bs-btn-active-color: #ffdf00;
  --bs-btn-active-border-color: #632285;
  --bs-btn-hover-bg: #632285cc;
  --bs-btn-hover-color: #ffdf00cc;
  --bs-btn-hover-border-color: #632285cc;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;SS
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
`;

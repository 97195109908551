import React from "react";
import styled from "styled-components";
import { Colors } from "../shared/Constants";

const StyledInfoRef = styled.div`
  scroll-margin-top: ${(props) => props.marginscrolltop || 0}px;
  background-color: ${Colors.InformationBackground};
  margin-bottom: 10px;
  color: #56365c;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 5%;
  padding-top: 5%;
`;

const StyledNames = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 10px;
  @media (max-width: 575px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Info = ({ infoRef, marginScrollTop }) => {
  return (
    <StyledInfoRef ref={infoRef} marginscrolltop={marginScrollTop}>
      <h2>Information</h2>
      <div>
        På Carlshälls Gård erbjuds det bekvämligheten att parkera över natten
        och hämta bilen dagen efter. För extra trygghet låser de grinden och
        endast gäster med en kod har tillträde.
      </div>
      <br></br>
      <div>
        För er som behöver boende i närheten finns flera alternativ att välja
        mellan:
      </div>{" "}
      <br></br>
      <ul
        style={{
          listStyle: "none",
          paddingLeft: 0,
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://langholmen.com/"
          >
            Långholmen Hotell
          </a>{" "}
          är det närmaste hotellet och ligger bara 5 minuters gångavstånd från
          Carlshälls Gård.
        </li>

        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.strawberry.se/hotell/sverige/stockholm/clarion-hotel-amaranten/?gad_source=1&gclid=CjwKCAiA2pyuBhBKEiwApLaIO6mywcVyyXzRxdF42utp2WHEJYQcLbGeOJvb1VC1tNXhXYiLRG5oTRoC1gEQAvD_BwE"
          >
            Clarion Hotel Amaranten
          </a>{" "}
          är ett modernt hotell med bekväma rum och närhet till citylivet, bara
          en kort bilresa från Carlshälls Gård.
        </li>

        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.thonhotels.com/sv/hoteller/sverige/stockholm/kungsbron/?currency=SEK&gad_source=1&gclid=CjwKCAiA2pyuBhBKEiwApLaIO-zYUs1XAJpE48tT3Uf7rchMN78XDwQ6xaeGX3hLbG4zzRDZ6X0wlxoCMRgQAvD_BwE&gclsrc=aw.ds"
          >
            Theon Partner Hotel Kungsbron{" "}
          </a>{" "}
          är ett centralt beläget hotell vid Kungsbron, vilket ger enkel
          tillgång till centralstationen i Stockholm.
        </li>
      </ul>
      <br></br>
      <h3>Meny och allergier</h3>
      Maten som serveras under middagen kommer att justeras efter era eventuella
      allergier. Vi ber er därför att ange era allergier i samband med O.S.A för
      att vi ska kunna tillgodose era behov på bästa sätt. Utöver det så kommer
      baren att vara tillgänglig för alla, det är dock bra att veta att den är
      kontantfri.
      <br></br>
      <br></br>
      <div>
        Nu har vi varit på menyprovning och bestämt vilka rätter som kommer att
        serveras under middgaen. Til förrätt serveras Carpaccio med
        tryffel-pecorino kräm, scharlottenlök och rostade cashewnötter. Därefter
        serveras Lammytterfilé med lökpuré, krispig potatis, picklad lök och en
        madeirasås till varmrätten. Till efterrätt kommer en otrolig kokos
        panacotta serveras med kokoskräm och kokosmaräng. Vi har såklart tagit
        hänsyn till alla ni som har allergier och andra matpreferenser där
        rätterna kommer att justeras efter era behov och önskemål.
      </div>
      <br></br>
      <br></br>
      <h3>
        <strong>OBS! </strong>Bra att veta
      </h3>
      <div>
        Vi vill ta chansen att gladeligen presentera kvällens två toastmasters
        Linus och Nahrin som ni kan ta kontakt med vid önskemål om att hålla tal
        eller övriga frågor. Här kommer deras kontaktuppgifter:
      </div>
      <br></br>
      <StyledNames>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>Nahrin Youssef</p>
          <p>+46 70-354 44 36</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>Linus Thelander Sjöstrand</p>
          <p> +46 73-400 10 02</p>
        </div>
      </StyledNames>
      <br></br>
      <div>
        För de som undrar vad de kan ge oss i bröllopspresent, så sparar vi just
        nu till vår bröllopsresa, men det är inte det som är det viktigaste.
        Tack för att ni är med och delar denna speciella dag med oss!
      </div>
    </StyledInfoRef>
  );
};

export default Info;

import React, { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import RsvpContent from "../../rsvpContent/RsvpContent";
import { CloseButton } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalRSVP = ({ show, handleClose, SucessfulRSVP }) => {
  const isFullScreen = useRef(window.screen.width < 575);

  useEffect(() => {
    const handleChange = () => {
      isFullScreen.current = window.screen.width < 575;
    };

    window.addEventListener("resize", handleChange);

    return () => window.removeEventListener("resize", handleChange);
  }, []);

  return (
    <Modal
      centered
      fullscreen={isFullScreen.current}
      className={!isFullScreen.current && "rounded-0"}
      show={show}
      onHide={handleClose}
      style={{ opacity: show ? 1 : 0 }}
    >
      <Modal.Body
        className={`square bg-primary ${!isFullScreen.current && "rounded"}`}
        style={{
          backgroundImage:
            "linear-gradient(49deg, rgb(246, 243, 231) 0%, rgb(255, 216, 248) 46%, rgb(153, 85, 187) 100%)",
        }}
      >
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: " 100%",
            border: "none",
          }}
        >
          <Modal.Title
            style={{
              marginLeft: "auto",
              color: "#ffdf00",
              textShadow:
                "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
              fontSize: "2rem",
            }}
          >
            O.S.A
          </Modal.Title>

          <CloseButton data-bs-theme="dark" onClick={handleClose} />
        </Modal.Header>
        <ToastContainer />
        <RsvpContent SucessfulRSVP={SucessfulRSVP} handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
};

export default ModalRSVP;

import React, { useState } from "react";
import FloatingLabelInput from "../shared/formUtils/FloatingLabelInput";
import { StyledRSVPContent } from "./StyledRsvpContent";
import { Form } from "react-bootstrap";

import { addDoc, collection } from "firebase/firestore";
import { db } from "../../server/Firebase";
import { StyledButton } from "../shared/SharedStyledButton";
import { getAuth } from "firebase/auth";
import { toast, Zoom } from "react-toastify";

const RsvpContent = ({ handleClose, SucessfulRSVP }) => {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);

  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;

  const auth = getAuth();
  const deviceID = `${userAgent}-${platform}-${
    auth.currentUser ? auth.currentUser.uid : ""
  }`;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    allergies: "",
    userId: auth.currentUser ? auth.currentUser.uid : "",
    deviceID: deviceID,
  });

  const showErrorMessage = () => {
    toast.error(
      <div>
        <div>Någonting gick fel,</div>
        <div> vänligen försök igen!</div>
      </div>,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
      }
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        await addDoc(collection(db, "Guests", user.uid, "Lista"), formData)
          .then(() => setError(false))
          .catch((e) => {
            setError(true);
          });
      } else {
        setError(true);
      }

      if (error) {
        showErrorMessage();
      } else {
        handleClose();
        SucessfulRSVP();
      }
    }
    setValidated(true);
  };

  return (
    <StyledRSVPContent>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <FloatingLabelInput
          controlId="nameId"
          label="Namn"
          type="text"
          placeholder="George"
          onChange={(name) => setFormData({ ...formData, name })}
          required
        />
        <FloatingLabelInput
          controlId="emailId"
          label="E-mail"
          type="email"
          placeholder="name@example.com"
          onChange={(email) => setFormData({ ...formData, email })}
          required
        />
        <FloatingLabelInput
          controlId="allergyId"
          label="Allergier"
          type="text"
          placeholder="Nötter"
          onChange={(allergies) => setFormData({ ...formData, allergies })}
        />
        <div className="d-flex justify-content-center">
          <StyledButton type="submit">Skicka</StyledButton>
        </div>
      </Form>
    </StyledRSVPContent>
  );
};

export default RsvpContent;

import styled from "styled-components";
import colorfulFlowersRight from "./assets/colorfulFlowersRight.webp";
import colorfulFlowersLeft from "./assets/colorfulFlowersLeft.webp";

import { device } from "./components/shared/Constants";

export const StyledApp = styled.div`
  color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const StyledHeaderBottom = styled.div`
  display: flex;
  position: relative;
  background-color: #f6f3e7;
  justify-content: flex-start;
  height: 60svh;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
`;

export const StyledHeaderTop = styled.div`
  position: relative;
  background-color: #f6f3e7;
  height: 30svh;
`;

export const StyledDate = styled.div`
  color: #ffdf00;
  text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px black;

  font-family: chopin;
  font-size: 3.5rem;
  @media (max-width: 575px) {
    font-size: 2rem;
    align-items: center;
  }
`;

export const StyledStickyHeader = styled.div`
  background-color: ${(props) => props.stickyheaderbackground};
  position: sticky;
  padding-bottom: 10px;
  flex-direction: column;
  z-index: 7;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const StyledTitle = styled.div`
  font-size: 6rem;
  color: #ffdf00;
  text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px black;
  font-family: Chopin;
  font-weight: 400;
  font-style: italic;
  display: flex;
  gap: 10px;
  flex-direction: row;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  @media (max-width: 575px) {
    font-size: 3rem;
    align-items: center;
  }
`;
export const StyledMainPage = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
  height: 100svh;
  width: 100svw;
  overflow: hidden;
`;

export const StyledLeftArea = styled.div`
  z-index: 4;
  height: 100svh;

  background-image: url(${colorfulFlowersLeft});
  overflow: hidden;
  background-size: contain;
`;

export const StyledCenterArea = styled.div`
  z-index: 2;
  position: absolute;
  background-color: #f6f3e7;
  height: 100svh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (${device.iphone_down}) {
    text-align: center;
  }
`;

export const StyledRightArea = styled.div`
  z-index: 4;
  height: 100svh;
  background-image: url("${colorfulFlowersRight}");
  background-size: contain;
  overflow: hidden;
`;

export const StyledImage = styled.div`
  height: 500px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  img {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }
`;

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC5zEj225R_3F7EZSN1WJ7VssyoqZVjGuc",
  authDomain: "mary-gustav-wedding.firebaseapp.com",
  databaseURL:
    "https://mary-gustav-wedding-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mary-gustav-wedding",
  storageBucket: "mary-gustav-wedding.appspot.com",
  messagingSenderId: "562862350242",
  appId: "1:562862350242:web:1847022692acc80a6c9cfb",
  measurementId: "G-2XXG65SC13",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export default app;

import styled from "styled-components";
import { Colors, device } from "../shared/Constants";

export const StyledAgenda = styled.div`
  scroll-margin-top: ${(props) => props.marginscrolltop || 0}px;
  align-items: center;
  display: flex;
  background-color: ${Colors.AgendaBackground};
  color: #ffdf00;
  flex-direction: column;
  padding-top: 5%;
  padding-bottom: 5%;

  justify-content: flex-start;
  padding-left: 20%;
  padding-right: 20%;
`;

export const StyledDestination = styled.div`
  scroll-margin-top: ${(props) => props.marginscrolltop || 0}px;
  background-color: ${Colors.DestinationBackground};
  color: #00670c;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 20%;
  padding-right: 20%;

  flex-wrap: wrap;
  gap: 30px;

  // TODO: KIKA PÅ DETTA
`;

export const StyledMapWithAdressWrapper = styled("div", {
  shouldForwardProp: (props) => props !== "borderleft",
})(
  ({ borderleft }) => `
  display: flex;
  flex-flow: column;

  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  border-left: ${borderleft === 1 && "2px solid #333"};
  border-right: ${borderleft === 0 && "2px solid #333"};
  padding: 5px;
  @media  ${device.iphone_down} {
    border: 0px solid;
  }
`
);

import React from "react";
import { StyledDestination, StyledMapWithAdressWrapper } from "./StyledPages";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { device } from "../shared/Constants";

import marker from "../../assets/marker.png";
const StyledMap = styled.div`
  height: 200px;
  width: 200px;
  z-index: 0;

  border-radius: 50%;
  border: 2px solid white;

  overflow: hidden;
  color: #ac9377;
  background-color: white;

  > img {
    width: 100%;
    transition: 0.5s scale ease-in-out;
  }

  :hover {
    opacity: 0.8;
    background-color: orange;
    cursor: pointer;
    transform: scale(1.3);
  }
`;

const StyledMapInfo = styled.div`
  display: flex;
  flex-direction: row;
  color: #ac9377;
  @media (${device.iphone_down}) {
    flex-direction: column-reverse;
  }
`;

const StyledDesinationInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: #ac9377;
`;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Destination = ({ destinationRef, marginScrollTop }) => {
  const handleRedirect = (e, link) => {
    e.preventDefault();
    window.open(link, "_blank");
  };

  const StyledVigseln = styled.div`
    padding-right: 10px;
    text-align: left;
    @media (${device.iphone_down}) {
      text-align: center;
    }
  `;
  const StyledMiddagen = styled.div`
    padding: 10px;
    text-align: left;
    @media (${device.iphone_down}) {
      text-align: center;
    }
  `;

  return (
    <StyledDestination ref={destinationRef} marginscrolltop={marginScrollTop}>
      <h2 style={{ color: "#ac9377" }}>Destination</h2>
      <StyledDesinationInfo>
        <h3>Vigseln</h3>
        <StyledMapInfo>
          <StyledVigseln>
            Den kyrkliga vigseln äger rum i Högalidskyrkan. För att ta dig till
            kyrkan kan du ta buss 4 eller 66. Du kan även åka tunnelbanans röda
            linje och kliva av i Hornstull. Kyrkan ligger på Högalidsberget på
            Södermalms västra del. Från Varvsgatan går Högalids kyrkväg upp mot
            kyrkan. Det går även bra att ta trapporna upp mot berget från
            Hornstulls tunnelbana, uppgång Högalidsparken eller trappor upp från
            Högalidsgatan. Åker du bil rekommenderar vi att du hittar en
            gatuparkering då det enbart finns ett fåtal parkeringsplatser för
            besökare vid kyrkan. Använd{" "}
            <a
              href="https://www.google.com/maps/dir//H%C3%B6galid+Church,+H%C3%B6galids+kyrkv%C3%A4g+7,+117+30+Stockholm/@59.3174698,18.0350902,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x465f77c4bfda66c5:0x32aa5cda3f5e6425!2m2!1d18.0376651!2d59.3174698!3e0?entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
            >
              denna länk
            </a>
            {" för en vägbeskrivning."}
          </StyledVigseln>
          <StyledMapWithAdressWrapper
            borderleft={1}
            onClick={(e) =>
              handleRedirect(
                e,
                "https://www.google.com/maps/place/H%C3%B6galid+Church/@59.3175099,18.0371176,17z/data=!4m6!3m5!1s0x465f77c4bfda66c5:0x32aa5cda3f5e6425!8m2!3d59.3174698!4d18.0376651!16s%2Fg%2F11x1nlxyq?entry=ttu"
              )
            }
          >
            <StyledMap>
              <Image
                onError={(event) => (event.target.src = marker)}
                width="250"
                height="250"
                alt="karta"
                src={`https://maps.googleapis.com/maps/api/staticmap?center=59.3174845,18.0378837&zoom=17&size=250x250&key=${GOOGLE_MAPS_API_KEY}`}
              />
            </StyledMap>
            <i style={{ padding: "10px", textAlign: "center" }}>
              Högalidskyrkan,
              <br /> Högalids kyrkväg 7,
              <br /> 117 30 Stockholm
            </i>
          </StyledMapWithAdressWrapper>
        </StyledMapInfo>
      </StyledDesinationInfo>
      <StyledDesinationInfo>
        <h3>Middagen</h3>

        <StyledMapInfo>
          <StyledMapWithAdressWrapper
            borderleft={0}
            style={{
              padding: "5px",
            }}
          >
            <StyledMap
              onClick={(e) =>
                handleRedirect(
                  e,
                  "https://www.google.com/maps/place/Carlsh%C3%A4ll/@59.3217492,18.0175892,17z/data=!4m6!3m5!1s0x465f77d27f2ab8c7:0xa8edded544209dd8!8m2!3d59.322005!4d18.017762!16s%2Fg%2F1th61_g2?entry=ttu"
                )
              }
            >
              <Image
                onError={(event) => (event.target.src = marker)}
                width="250"
                height="250"
                alt="karta"
                src={`https://maps.googleapis.com/maps/api/staticmap?center=59.3217492,18.0175892&zoom=17&size=250x250&key=${GOOGLE_MAPS_API_KEY}`}
              />
            </StyledMap>
            <i style={{ padding: "10px", textAlign: "center" }}>
              Carlshälls Gård , Karlshällsvägen 14,
              <br /> 117 33 Stockholm
            </i>
          </StyledMapWithAdressWrapper>
          <StyledMiddagen>
            Efter vigseln i kyrkan kommer vi att samlas i Carlshälls Gård som
            ligger på Långholmen. Vi rekommenderar att ta en Uber från kyrkan
            till Carlshälls Gård för en smidigare upplevelse. Annars kan du ta
            buss 66 som stannar strax innan långholmen, så kan du promenera över
            bron och upp till herrgården på vänstra sidan av ön. Det står
            tydligt skyltat när du är på långholmen. Åker du bil så finns det
            gratis parkeringsplatser på Carlshälls Gård i mån av plats. Använd{" "}
            <a
              href="https://www.google.com/maps/dir//Carlsh%C3%A4ll,+Karlsh%C3%A4llsv%C3%A4gen+14,+117+33+Stockholm/@59.3217492,18.0175892,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x465f77d27f2ab8c7:0xa8edded544209dd8!2m2!1d18.0177977!2d59.3219715!3e0?entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
            >
              denna länk
            </a>
            {" för en vägbeskrivning."}
          </StyledMiddagen>
        </StyledMapInfo>
      </StyledDesinationInfo>
    </StyledDestination>
  );
};

export default Destination;
